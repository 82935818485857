import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/daystate'
const CONTROL_URL = '/api/daystate-check/'

class DaystateService extends ApiService {
  import(daystate_id) {
    return http.post(`${this.getUrl()}/${daystate_id}/import`)
  }
  export(daystate_id){
    return http.post(`${this.getUrl()}/${daystate_id}/export`)
  }
  download(daystate_id){
    return http.get(`${this.getUrl()}/${daystate_id}/download`, { responseType: 'blob' })
  }
  exportlines(daystate_id, type){
    return http.post(`${this.getUrl()}/${daystate_id}/exportlines`, {type: type}, { responseType: type == 'html' ? 'text/html' : 'blob' })
  }

  getUrl(){
    return URL
  }

  updateControl(data){
    return http.put(CONTROL_URL + data.id, data)
  }

  testConnection(locationId, date){
    return http.get(`${this.getUrl()}/connection/test/${locationId}?date=${date}`)
  }
}

export default new DaystateService()
